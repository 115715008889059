import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Subscribe } from 'unstated';

import { token } from '@atlaskit/tokens';
import FeatureGates from '@atlaskit/feature-gate-js-client';

import { HeaderItemsContainer } from '@confluence/header-items-manager';
import { ViewAttachmentsButton } from '@confluence/attachments';
import { DraftStatus, ScheduledStatus } from '@confluence/content-metadata';
import { Attribution, ErrorBoundary } from '@confluence/error-boundary';
import { JiraButtonAnalyticsWrapper } from '@confluence/jira-metadata';
import { RestrictionsDialogTrigger } from '@confluence/restrictions';
import { ShareButton } from '@confluence/share';
import { ShareAndRestrictButton } from '@confluence/share-and-restrict-dialog';
import {
	WebItem,
	INVITE_TO_EDIT_ID,
	DRAFT_STATUS_LOZENGE_ID,
	CONTENT_METADATA_JIRA_ID,
	ANALYTICS_ADDON_BYLINE_ID,
	SCHEDULED_STATUS_LOZENGE_ID,
	ANALYTICS_ADDON_ACTIONS_MENU_ID,
	CONTENT_METADATA_ATTACHMENTS_ID,
	CONTENT_METADATA_PAGE_RESTRICTIONS_ID,
} from '@confluence/web-item';

export const SHARE_BTN_STYLE = {
	fontSize: '14px',
	display: 'inline-block',
	verticalAlign: 'top',
	paddingLeft: token('space.100', '8px'),
};

export const WebItemList = (props) => {
	const {
		webItems,
		actions,
		modifications,
		className,
		id,
		disableIcons,
		webItemClassName,
		ContainerTagName,
		pageId,
		dataTestId,
		contentSubType,
		hidePrecedingDot,
	} = props;

	const isShareAndRestrictDialogFfEnabled = FeatureGates.checkGate(
		'confluence_frontend_unified_restrict_and_share',
	);

	return (
		<Subscribe to={[HeaderItemsContainer]}>
			{({ markItem }) => (
				<ContainerTagName
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className={className}
					id={id}
					data-test-id={dataTestId} // editor button tests use this, do not remove
					data-testid={dataTestId || 'web-item-wrapper'}
				>
					{webItems.map((webItem, index) => {
						const webItemProps = {
							key: webItem.completeKey,
							url: webItem.url,
							id: webItem.id,
							onClick: webItem.onClick || actions[webItem.moduleKey],
							className: classnames(webItem.styleClass, webItemClassName, {
								'with-dot':
									// Always show the dot before for non-live pages and for live pages only show dot for items after the first one
									!hidePrecedingDot || index > 0,
							}),
							params: webItem.params,
							iconInfo: webItem.icon,
							label: webItem.label,
							tooltip: webItem.tooltip,
							spinner: modifications[webItem.moduleKey]
								? modifications[webItem.moduleKey].spinner
								: false,
							disableIcon: disableIcons,
							iconStyle: webItem.iconStyle,
						};

						// Wow! The JIRA metadata item needs this data prop to work correctly! That was an easy debug ha hoo
						if (webItem.id === CONTENT_METADATA_JIRA_ID) {
							webItemProps['data-jira-metadata-count'] = -1;
						}

						// "Unpublished Changes" lozenge
						if (pageId && webItem.id === DRAFT_STATUS_LOZENGE_ID) {
							if (webItem.moduleKey === SCHEDULED_STATUS_LOZENGE_ID) {
								// Only show "Scheduled publish" lozenge
								return <ScheduledStatus key={pageId} contentId={pageId} />;
							} else {
								return <DraftStatus key={pageId} contentId={pageId} />;
							}
						}

						// TODO(brizvash): move the button to header, no need to check it all the time
						if (webItem.id === CONTENT_METADATA_ATTACHMENTS_ID) {
							return <ViewAttachmentsButton key="view-attachments-button" />;
						}

						if (webItem.id === INVITE_TO_EDIT_ID) {
							return (
								// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
								<span style={SHARE_BTN_STYLE} key="fabric-share">
									{isShareAndRestrictDialogFfEnabled ? (
										<ErrorBoundary
											attribution={Attribution.PERMISSIONS_EXPERIENCE}
											key="share-and-restrict-button"
										>
											<ShareAndRestrictButton
												markItem={markItem}
												contentId={webItem.contentId}
												contentType={webItem.contentType ?? 'page'}
												contentSubType={contentSubType || undefined}
											/>
										</ErrorBoundary>
									) : (
										<ShareButton
											contentId={webItem.contentId}
											markItem={markItem}
											contentSubType={contentSubType || undefined}
										/>
									)}
								</span>
							);
						}

						if (webItem.id === CONTENT_METADATA_PAGE_RESTRICTIONS_ID) {
							// Dialog is already attached to view page level
							return isShareAndRestrictDialogFfEnabled ? null : (
								<ErrorBoundary
									attribution={Attribution.PERMISSIONS_EXPERIENCE}
									key="restrictions-dialog-trigger"
								>
									<RestrictionsDialogTrigger
										contentId={webItem.contentId}
										triggeredFrom="fromView"
										markItemAsLoaded={markItem}
										contentSubType={contentSubType}
									/>
								</ErrorBoundary>
							);
						}

						if (
							webItem.id === ANALYTICS_ADDON_ACTIONS_MENU_ID ||
							webItem.id === ANALYTICS_ADDON_BYLINE_ID
						) {
							return <></>;
						}

						if (webItem.id === CONTENT_METADATA_JIRA_ID) {
							return (
								<JiraButtonAnalyticsWrapper key={webItem.completeKey}>
									<WebItem {...webItemProps} />
								</JiraButtonAnalyticsWrapper>
							);
						}

						return <WebItem key={webItem.completeKey} {...webItemProps} />;
					})}
				</ContainerTagName>
			)}
		</Subscribe>
	);
};

WebItemList.displayName = 'WebItemList';
WebItemList.defaultProps = {
	disableIcons: false,
	ContainerTagName: 'div',
	actions: {},
	modifications: {},
};
WebItemList.propTypes = {
	webItems: PropTypes.array,
	actions: PropTypes.object,
	modifications: PropTypes.object,
	className: PropTypes.string,
	id: PropTypes.string,
	disableIcons: PropTypes.bool,
	ContainerTagName: PropTypes.string,
	webItemClassName: PropTypes.string,
	pageId: PropTypes.string,
	dataTestId: PropTypes.string,
	contentSubType: PropTypes.string,
	hidePrecedingDot: PropTypes.bool,
};
