import { useEffect, useState } from 'react';

import { type CoordinationClientType } from '../coordination-client';

type MessageIsStarted = boolean;
type StopMessageCallback = (force?: boolean) => Promise<void>;

export type UseCoordinationType = [MessageIsStarted, StopMessageCallback];

export const useCoordination = (
	coordinationClient: CoordinationClientType,
	messageId: string,
): UseCoordinationType => {
	const [started, setStarted] = useState(false);
	useEffect(() => {
		(async () => {
			try {
				const started = await coordinationClient.start(messageId);
				if (started) {
					setStarted(true);
				}
			} catch (err) {
				// eslint-disable-next-line no-console
				console.error(`Unable to start flow message ${messageId}. ${err}`);
			}
		})();
	}, [coordinationClient, messageId]);
	const stop = async (force?: boolean) => {
		if (!force && !started) {
			return;
		}
		try {
			await coordinationClient.stop(messageId);
		} catch (err) {
			// eslint-disable-next-line no-console
			console.error(`Unable to stop flow message ${messageId}. ${err}`);
		}
	};

	return [started, stop];
};
